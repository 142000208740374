$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1170px
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1230px
);

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 1,
    2: $spacer * 2,
    3: $spacer * 3,
    4: $spacer * 4,
    5: $spacer * 5,
    6: $spacer * 6
);

$grid-columns: 60;
$grid-gutter-width: 2rem;

[v-cloak] {
    display: none;
    .v-cloak {
        min-height: 40px;
        &--block {
            display: block !important;
        }
        &--inline {
            display: inline !important;
        }
        &--inlineBlock {
            display: inline-block !important;
            position: relative;
        }
        &--hidden {
            display: none !important;
        }
        &--invisible {
            visibility: hidden;
        }
    }
}