
@import '../../sass/abstracts/_mixins.scss';

article {
    border-radius: 1rem;
    background-color: var(--global-color-white);
    overflow: hidden;
    padding: 1.5rem;
    position: relative;

    h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    h4 {
        font-size: 0.8rem;
    }

    ul {
        column-count: 2;

        @include media-breakpoint-down(sm) {
            column-count: 1;
        }
    }

    ul li {
        font-size: 0.8rem;
        color: var(--global-color-grey);
        margin-bottom: 1rem;

        svg {
            margin-right: 0.6rem;
        }
    }

    .row {
        z-index: 2;
        position: relative;
    }

    p {
        font-size: 0.8rem;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, #ffecf1 0%, #fff 57%);
        z-index: 1;

        @include media-breakpoint-down(sm) {
            background: linear-gradient(90deg, #ffecf1 0%, #fff 97%);
        }
    }
}
