

.educational {
    margin-top: 24px;
    border-radius: 16px;
    border: 1px solid var(--Colors-Base-white, #FFF);
    background-color: var(--global-color-white);
    display: flex;
    padding: var(--lg, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    > .row > p {
      margin-left: 32px;
    }
    > .row > h2 > span {
      margin-left: 8px;
    }
  }

section {
    background: linear-gradient(
        180deg,
        #f4f5f7 0%,
        rgba(244, 245, 247, 1.21) 70.21%,
        rgba(244, 245, 247, 0) 102.15%
    );
}

.pricing-plan-switcher {
    background-color: var(--global-color-white);
    padding: 0.5rem !important;
    border-radius: 15rem;
    gap: 0.5rem;

    button {
        padding: 0.625rem 0.75rem;
        font-size: 0.8rem;
        color: var(--global-color-grey);
        border-radius: 15rem;
        border: 0.07rem solid transparent;
        height: 100%;

        span {
            display: inline-block;
            margin-left: 0.5rem;
            background-color: var(--global-color-primary);
            color: var(--global-color-white);
            font-size: 0.7rem;
            padding: 0.25rem 0.5rem;
            border-radius: 1rem;
        }

        &.active {
            border-color: rgba(250, 31, 99, 0.1);
            background: linear-gradient(52deg, #ffeaf0 0%, #fff 100%);
            color: var(--global-color-primary);
        }
    }
}
