
@import "../../sass/abstracts/mixins";

.modal {
	align-items: center;
	display: flex;
  background-color: rgba(0,0,0,0.4);
	@include media-breakpoint-down(md) {
		align-items: start;
	}
}

.modal-dialog {
	width: 100%;
	max-width: 60rem;
}

.modal-content {
	border-radius: 1.4rem;
	border: none;
	box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px #00000005, 0px 6.650102138519287px 5.32008171081543px 0px #00000007, 0px 12.521552085876465px 10.017241477966309px 0px #00000009, 0px 22.3363094329834px 17.869047164916992px 0px #0000000b, 0px 41.777610778808594px 33.422088623046875px 0px #0000000d, 0px 100px 80px 0px #00000012;
}

.modal-body {
	padding: 6rem 4rem 4rem;

	@include media-breakpoint-down(md) {
		padding: 4rem 1rem 1rem;
	}
}

p {
	font-size: 1.1rem;
}

h2 {
	font-size: 1.5rem !important;
	max-width: 25rem;
	margin: 1rem 0 3rem 0;
}

.close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 10;
}

.close:hover > svg > path{
  transition: var(--animation-transition);
}

.close:hover > svg > path{
  filter: invert(35%) sepia(84%) saturate(7149%) hue-rotate(331deg) brightness(107%) contrast(96%);
}
