
article {
    border-radius: 1rem;
    background-color: var(--global-color-white);
    border: 0.08rem solid transparent;
    overflow: hidden;

    &:hover {
        border-color: rgba(250, 31, 99, 0.6);

        .pricing-box-header:before {
            opacity: 1;
        }
    }
}

.pricing-box-header {
    position: relative;

    & > div {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #ffebf1 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
        transition: opacity 0.2s ease;
        opacity: 0;
    }
}

.pricing-box-header,
.pricing-box-body {
    padding: 1.5rem;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

p {
    font-size: 0.8rem;
}

.price {
    font-size: 2.5rem;
}

ul li {
    font-size: 0.8rem;
    color: var(--global-color-grey);
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.7rem;

    &:last-child {
        margin-bottom: 0;
    }

    &.disabled {
        color: rgba(22, 22, 22, 0.25);
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
}

h4 {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #525252;
}

small {
    font-size: 0.75rem;
    color: var(--global-color-grey);
    margin-top: -0.4rem;
    display: block;
}
